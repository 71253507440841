import { useState, useContext, Suspense, useEffect } from 'react'
import { PageLayout } from '../../layout'
import {
  AgxButton,
  AgxTextInput,
  AgxRow,
  AgxColumn,
  AgxLabel,
  AgxHeader,
  AgxToast,
  Images,
} from '@urbanx/agx-ui-components'
import { Agency } from 'types/agency'
import { Link, useNavigate } from 'react-router-dom'
import { Pages } from 'constants/menu'
import { PlaceHolderImage } from 'components'
import { UserContext, AgencyContext } from 'contexts'
import Popup from '../../components/Popup/Popup'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { GetAllAgencies, CreateAgency } from 'services'
import { AgxToastState } from 'types/commonTypes'
import { useAzureAuth } from 'hooks/useAzureAuth'
import './dashboard.css'
import slugify from 'slugify'

const DashboardPage = () => {
  const user = useContext(UserContext)
  const [_, setSelectedAgency] = useContext(AgencyContext)
  const queryClient = useQueryClient()
  const [showPopup, setShowPopup] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [localAgencies, setLocalAgencies] = useState<Agency[]>([])
  const [agencyName, setAgencyName] = useState('')
  const [toastState, updateToastState] = useState<AgxToastState>({
    color: 'success',
    message: '',
    open: false,
  })

  const [, getAuthToken] = useAzureAuth()
  const navigate = useNavigate()

  const { data: agencies, isLoading: isLoadingAgencies } = useQuery<
    Agency[] | undefined
  >({
    queryKey: ['all-agencies'],
    queryFn: () => GetAllAgencies(getAuthToken),
  })

  const { mutate: createAgency } = useMutation(CreateAgency, {
    onSuccess: (agencyId) => {
      queryClient.invalidateQueries({
        queryKey: ['all-agencies'],
      })
      navigate(`/${agencyId}/agency-details/`)
      updateToastState({
        color: 'success',
        message: 'Agency added successfully',
        open: true,
      })
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: 'Error adding agency',
        open: true,
      })
    },
  })

  const linkClickHandler = (agency: Agency) => {
    setSelectedAgency(agency)
  }

  const addNewAgency = () => {
    setShowPopup(true)
  }

  const createNewAgency = () => {
    createAgency({ name: agencyName, getAuthToken })
    setShowPopup(false)
  }

  useEffect(() => {
    if (agencies) {
      if (searchText === '') {
        setLocalAgencies([...agencies])
      } else {
        const filteredAgencies = agencies.filter(
          (agency) =>
            agency.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
        )
        setLocalAgencies([...filteredAgencies])
      }
    }
  }, [searchText, agencies])

  useEffect(() => {
    setSelectedAgency(undefined)
  }, [])
  const renderAgency = (agency: Agency, index: number) => {
    return (
      <tr
        key={`Agency-${index}-${agency.id}`}
        className="tableRowStyle__dashboard"
      >
        <td width={'76px'}>
          {agency.logoUrl ? (
            <div className="imageContainer">
              <img
                src={agency.logoUrl}
                className="agencyLogo"
                style={{ background: agency.backgroundColor }}
              />
            </div>
          ) : (
            <PlaceHolderImage size={60} title={agency.name} />
          )}
        </td>
        <td data-testid={`agencyName-${slugify(agency.name)}-${index}`}>
          <AgxHeader size={4}>{agency.name}</AgxHeader>
        </td>
        <td align="right">
          <Link
            data-testid={`brandingLink-${index}`}
            onClick={() => linkClickHandler(agency)}
            to={`${agency.id}/${Pages.BRANDING}`}
            className="agencyTableLinkStyle__dashboard"
          >
            Manage Brand
          </Link>
        </td>
        <td align="right">
          <Link
            data-testid={`teamsLink-${index}`}
            to={`${agency.id}/${Pages.TEAMS}`}
            onClick={() => linkClickHandler(agency)}
            className="agencyTableLinkStyle__dashboard"
          >
            Manage Team
          </Link>
        </td>
        <td align="right">
          <Link
            data-testid={`markettingLink-${index}`}
            to={`${agency.id}/${Pages.MARKETING}`}
            onClick={() => linkClickHandler(agency)}
            className="agencyTableLinkStyle__dashboard"
          >
            Marketing packages
          </Link>
        </td>
        <td align="right">
          <Link
            data-testid={`campaignsLink-${index}`}
            to={`${agency.id}/${Pages.CAMPAIGNS}`}
            onClick={() => linkClickHandler(agency)}
            className="agencyTableLinkStyle__dashboard"
          >
            Campaigns
          </Link>
        </td>
      </tr>
    )
  }

  return (
    <PageLayout
      agentName={`${user?.firstName ?? ''} ${user?.lastName ?? ''}` || ''}
      agencyName="Bayside"
      isDashboard={true}
    >
      <>
        <AgxToast selector="#agxToast" toastState={toastState} />
        <AgxColumn largeGap>
          <AgxRow spaceBetween>
            <div
              className="horizontalContainer flexCentre noBorder"
              style={{ width: 500, height: 22 }}
            >
              <AgxLabel large>Agencies</AgxLabel>
              <AgxTextInput
                id="searchTxt"
                label=""
                defaultValue={searchText}
                onInputValueChange={({ value }: { value: string }) =>
                  setSearchText(value)
                }
                noOptionalLabel
                placeholder="Search"
                stretch
                leftIcon={<Images.SearchOutline />}
              />
            </div>

            <AgxButton
              text="New Agency"
              small
              primary
              onClick={() => addNewAgency()}
            />
          </AgxRow>
          {isLoadingAgencies ? (
            <Suspense>
              <AgxLabel extraClasses="errorTextStyle__dashboard">
                Loading Agencies...
              </AgxLabel>
            </Suspense>
          ) : (
            <AgxRow extraClasses="agenciesTable__dashboard">
              <table width={'100%'}>
                <tbody>
                  {localAgencies &&
                    localAgencies.map((a, index) => renderAgency(a, index))}
                </tbody>
              </table>
            </AgxRow>
          )}
        </AgxColumn>

        {showPopup ? (
          <Popup
            title="Create new agency"
            isOpen={showPopup}
            onClose={() => setShowPopup(false)}
            actionButtons={[
              {
                title: 'Create',
                isPrimary: true,
                clickHandler: createNewAgency,
              },
              {
                title: 'Cancel',
                clickHandler: () => setShowPopup(false),
              },
            ]}
            size={{ widthInPX: 400 }}
          >
            <div>
              <AgxTextInput
                id="agencyName"
                label="Agency name"
                noOptionalLabel
                stretch
                onInputValueChange={({ value }: { value: string }) =>
                  setAgencyName(value)
                }
              />
            </div>
          </Popup>
        ) : (
          <div></div>
        )}
      </>
    </PageLayout>
  )
}

export default DashboardPage
