import {
  AgxButton,
  AgxColumn,
  AgxRow,
  AgxSelect,
  Option,
} from '@urbanx/agx-ui-components'
import {
  AgencyUrbanXTeam,
  IAgencyUrbanXTeam,
  Staff,
  StaffRole,
} from 'types/staff'
import './ManageUrbanXTeam.scss'
import { useQuery } from '@tanstack/react-query'
import GetUrbanXStaffByRole from 'services/functions/team/getUrbanXStaffByRoles'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { useEffect, useState } from 'react'

interface ManageUrbanXTeamProps {
  agencyId: string
  urbanXTeam: AgencyUrbanXTeam
  onBackClick: () => void
  onSaveChanges: (agencyId: string, urbanXTeam: AgencyUrbanXTeam) => void
}

const ManageUrbanXTeam = (props: ManageUrbanXTeamProps) => {
  const { agencyId, urbanXTeam, onBackClick, onSaveChanges } = props
  const [, getAuthToken] = useAzureAuth()
  const [staffTeam, setStaffTeam] = useState<AgencyUrbanXTeam>(
    urbanXTeam ?? new AgencyUrbanXTeam()
  )
  const defaultOption = {
    value: '',
    label: 'None',
  } as Option

  const {
    data: staff,
    refetch: refetchStaff,
    isFetching,
  } = useQuery<Staff[] | undefined>({
    queryKey: [
      `get-staff-by-roles`,
      [
        StaffRole.BusinessManager,
        StaffRole.ListingsAdmin,
        StaffRole.SalesAdmin,
        StaffRole.MarketingExpert,
      ],
    ],
    queryFn: (queryKey) => GetUrbanXStaffByRole(queryKey, getAuthToken),
  })

  useEffect(() => {
    refetchStaff()
  }, [])

  const GetStaffOptionsByRole = (role: StaffRole) => {
    if (!staff) return [] as Option[]

    const staffByRole = staff?.filter(
      (staffMember) => staffMember.role === role
    )

    const roleOptions = staffByRole?.map((staffMember: Staff) => ({
      value: staffMember?.email,
      label: `${staffMember?.firstName} ${staffMember.lastName}`,
    })) as Option[]

    roleOptions.push(defaultOption)
    return roleOptions
  }

  const updateStaffAssignment = (role: StaffRole, email: string) => {
    const staffMember = staff?.find((x) => x.email === email)
    const roleKey = Object.keys(staffTeam).find(
      (key) => role === key.toLowerCase()
    ) as keyof IAgencyUrbanXTeam

    if (roleKey) {
      setStaffTeam({ ...staffTeam, [roleKey]: staffMember })
    }
  }

  const setStaffSelection = (role: StaffRole) => {
    const staffByRole = GetStaffOptionsByRole(role)
    if (role === StaffRole.BusinessManager)
      return urbanXTeam?.businessManager == null
        ? defaultOption
        : staffByRole.find(
            (x) => x.value === urbanXTeam?.businessManager?.email
          )

    if (role === StaffRole.ListingsAdmin)
      return urbanXTeam?.listingsAdmin == null
        ? defaultOption
        : staffByRole.find((x) => x.value === urbanXTeam?.listingsAdmin?.email)

    if (role === StaffRole.SalesAdmin)
      return urbanXTeam?.salesAdmin == null
        ? defaultOption
        : staffByRole.find((x) => x.value === urbanXTeam?.salesAdmin?.email)

    if (role === StaffRole.MarketingExpert)
      return urbanXTeam?.marketingExpert == null
        ? defaultOption
        : staffByRole.find(
            (x) => x.value === urbanXTeam?.marketingExpert?.email
          )
  }

  if (isFetching) {
    return <></>
  }

  return (
    <>
      <AgxColumn extraLargeGap>
        <AgxRow spaceBetween extraClasses="borderBottomContainer">
          <AgxButton
            text={'Manage Staff'}
            large
            naked
            onClick={() => onBackClick()}
          />
          <AgxButton
            text="Save Changes"
            medium
            primary
            onClick={() => onSaveChanges(agencyId, staffTeam)}
          />
        </AgxRow>
      </AgxColumn>
      <AgxColumn extraClasses="teamSection" id="manage-staff-panel">
        <AgxColumn extraLargeGap>
          <AgxSelect
            id="bm-select"
            hideOptionalLabel
            small
            label="Business Manager"
            options={GetStaffOptionsByRole(StaffRole.BusinessManager)}
            defaultValue={setStaffSelection(StaffRole.BusinessManager)}
            onValueChanged={({ value: staffEmail }) =>
              updateStaffAssignment(StaffRole.BusinessManager, staffEmail)
            }
          />
          <AgxSelect
            id="ls-select"
            hideOptionalLabel
            label="Listings Administrator"
            options={GetStaffOptionsByRole(StaffRole.ListingsAdmin)}
            defaultValue={setStaffSelection(StaffRole.ListingsAdmin)}
            onValueChanged={({ value: staffEmail }) =>
              updateStaffAssignment(StaffRole.ListingsAdmin, staffEmail)
            }
          />
          <AgxSelect
            id="sa-select"
            hideOptionalLabel
            label="Sales Administrator"
            options={GetStaffOptionsByRole(StaffRole.SalesAdmin)}
            defaultValue={setStaffSelection(StaffRole.SalesAdmin)}
            onValueChanged={({ value: staffEmail }) =>
              updateStaffAssignment(StaffRole.SalesAdmin, staffEmail)
            }
          />
          <AgxSelect
            id="mx-select"
            hideOptionalLabel
            label="Marketing Expert"
            options={GetStaffOptionsByRole(StaffRole.MarketingExpert)}
            defaultValue={setStaffSelection(StaffRole.MarketingExpert)}
            onValueChanged={({ value: staffEmail }) =>
              updateStaffAssignment(StaffRole.MarketingExpert, staffEmail)
            }
          />
        </AgxColumn>
      </AgxColumn>
    </>
  )
}

export default ManageUrbanXTeam
