interface Props {
  height?: string
  width?: string
  padding?: string
  color?: string
  opacity?: number
}

const ChevronIcon = (props: Props) => {
  const {
    height = '12',
    width = '7',
    color = '#000000',
    padding = '0',
    opacity = 1,
  } = props

  return (
    <div style={{ padding: `${padding}`, opacity }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 7 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.66666 11.8334C5.86137 11.8337 6.05007 11.7659 6.2 11.6417C6.28438 11.5717 6.35413 11.4858 6.40526 11.3889C6.45639 11.2919 6.48788 11.1858 6.49795 11.0767C6.50801 10.9675 6.49644 10.8575 6.4639 10.7528C6.43137 10.6481 6.3785 10.5509 6.30833 10.4667L2.575 6.00003L6.175 1.52503C6.24422 1.43979 6.29591 1.34171 6.32711 1.23643C6.3583 1.13115 6.36838 1.02074 6.35676 0.911547C6.34515 0.802356 6.31206 0.696538 6.25942 0.600174C6.20677 0.50381 6.1356 0.418801 6.05 0.350032C5.96378 0.274168 5.8628 0.216945 5.75342 0.181954C5.64403 0.146963 5.5286 0.13496 5.41435 0.146698C5.30011 0.158435 5.18952 0.19366 5.08954 0.250161C4.98955 0.306663 4.90233 0.383221 4.83333 0.475032L0.808331 5.47503C0.685763 5.62414 0.618759 5.81118 0.618759 6.0042C0.618759 6.19722 0.685763 6.38425 0.808331 6.53337L4.975 11.5334C5.0586 11.6342 5.16479 11.7139 5.28497 11.7661C5.40514 11.8182 5.53591 11.8412 5.66666 11.8334Z"
          fill={color}
        />
      </svg>
    </div>
  )
}
export default ChevronIcon
