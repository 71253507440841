import { useQuery } from '@tanstack/react-query'
import {
  AgxBodyText,
  AgxColumn,
  AgxDiagonalUpArrowIcon,
  AgxDivider,
  AgxHeader,
  AgxRow,
  cleanFullAddress,
  formatCurrency,
  Images,
} from '@urbanx/agx-ui-components'
import clsx from 'clsx'
import { MenuTitles } from 'constants/menu'
import { AgencyContext, UserContext } from 'contexts'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { ContentLayout, PageLayout } from 'layout'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GetAllAgencies, GetCampaignsForAgency } from 'services'
import getCampaignStatus from 'services/functions/campaignFlow/getCampaignStatus'
import { Agency } from 'types/agency'
import {
  CampaignFlowCampaignStatus,
  CampaignStatusModel,
} from 'types/campaignFlow'
import { Campaign } from 'types/campaigns'

import './CampaignMarketing.scss'

const CampaignMarketing = () => {
  const user = useContext(UserContext)
  const [selectedAgency, setSelectedAgency] = useContext(AgencyContext)
  const [selectedCampaign, setSelectedCampaign] = useState<
    Campaign | undefined
  >()
  const [, getAuthToken] = useAzureAuth()

  const { agencyId, campaignId } = useParams()

  const agenciesQueryKey = ['all-agencies']

  const { data: agencies } = useQuery<Agency[] | undefined>({
    queryKey: agenciesQueryKey,
    queryFn: () => GetAllAgencies(getAuthToken),
  })

  const campaignsQueryKey = [`campaigns-${agencyId}`, agencyId]

  const { data: campaigns, isLoading: isLoadingCampaigns } = useQuery<
    Campaign[] | undefined
  >({
    queryKey: campaignsQueryKey,
    queryFn: (queryKey) => {
      return GetCampaignsForAgency(queryKey, getAuthToken)
    },
  })

  useEffect(() => {
    setSelectedAgency(agencies?.find((a) => a.id === agencyId))
  }, [agencyId, setSelectedAgency, agencies])

  useEffect(() => {
    if (!isLoadingCampaigns) {
      setSelectedCampaign(campaigns?.find((c) => c.id === campaignId))
    }
  }, [campaigns, campaignId, isLoadingCampaigns])

  const campaignFlowStatusKey = ['campaign-flow-status', campaignId]

  const {
    data: campaignFlowStatus,
    isLoading: campaignFlowStatusLoading,
    isFetched: campaignFLowStatusLoaded,
  } = useQuery<CampaignStatusModel | undefined>({
    queryKey: campaignFlowStatusKey,
    queryFn: (queryKey) => {
      return getCampaignStatus(queryKey, getAuthToken)
    },
  })

  const mapCampaignFlowStatus = (status?: CampaignFlowCampaignStatus) => {
    if (status == null) return <></>

    let cssClass = ''
    let text = ''

    switch (status) {
      case CampaignFlowCampaignStatus.AwaitingFunds:
        cssClass = 'awaitingFunds'
        text = 'Awaiting Funds'
        break
      case CampaignFlowCampaignStatus.PayLaterApplicationSigned:
        cssClass = 'payLaterApplicationSigned'
        text = 'Pay Later Application Signed'
        break
      case CampaignFlowCampaignStatus.Paid:
        cssClass = 'paid'
        text = 'Paid'
        break
      case CampaignFlowCampaignStatus.Cancelled:
        cssClass = 'cancelled'
        text = 'Cancelled'
        break
      case CampaignFlowCampaignStatus.PendingSettlement:
        cssClass = 'pendingSettlement'
        text = 'Pending Settlement'
        break
      case CampaignFlowCampaignStatus.Complete:
        cssClass = 'complete'
        text = 'Complete'
        break
      case CampaignFlowCampaignStatus.Withdrawn:
        cssClass = 'withdrawn'
        text = 'Withdrawn'
        break
      case CampaignFlowCampaignStatus.PayLaterDeclined:
        cssClass = 'payLaterDeclined'
        text = 'Pay Later Declined'
        break
      default:
        return <></>
    }

    return <div className={clsx('paymentStatusLabel', cssClass)}>{text}</div>
  }

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={selectedAgency?.name || ''}
      currentPageTitle={`Campaigns / ${cleanFullAddress(
        selectedCampaign?.address
      )}`}
      isBlankContract={true}
      selectedCampaign={selectedCampaign}
    >
      <ContentLayout
        hasSideMenu={true}
        activeMenu={MenuTitles.CAMPAIGN_MARKETING}
        isBlankContract={true}
      >
        <>
          {campaignFlowStatusLoading && <div>Loading...</div>}
          {!campaignFlowStatusLoading &&
            campaignFLowStatusLoaded &&
            !campaignFlowStatus && (
              <div>No campaign flow information found for campaign</div>
            )}
          {!campaignFlowStatusLoading &&
            campaignFLowStatusLoaded &&
            campaignFlowStatus && (
              <AgxColumn extraClasses="campaignMarketing" veryLargeGap>
                <AgxHeader size={5}>Payments</AgxHeader>
                <AgxDivider />
                <table className="campaignMarketingTable">
                  <tbody>
                    <tr>
                      <th align="left" className="column-name">
                        <AgxBodyText small> Marketing</AgxBodyText>
                      </th>
                      <th align="left" className="column-name">
                        <AgxBodyText small>Flow no.</AgxBodyText>
                      </th>
                      <th align="left" className="column-name">
                        <AgxBodyText small>Status</AgxBodyText>
                      </th>
                      <th align="left" className="column-name">
                        <AgxBodyText small>Total marketing</AgxBodyText>
                      </th>
                      <th align="left" className="column-name">
                        <AgxBodyText small>Vendor to pay</AgxBodyText>
                      </th>
                    </tr>
                    <tr className="marketingStatusRow">
                      <td>
                        <AgxRow mediumGap centered>
                          <Images.SparkleSmall />
                          <AgxHeader size={4}>
                            {
                              campaignFlowStatus.marketingStatus
                                ?.marketingPackageName
                            }
                          </AgxHeader>
                        </AgxRow>
                      </td>
                      <td>
                        <AgxRow mediumGap centered>
                          <a
                            href={
                              campaignFlowStatus.campaignFlowCampaignInfo
                                ?.campaignFlowCampaignUrl
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="campaignIdLink"
                          >
                            <AgxBodyText small extraClasses="campaignIdLink">
                              {
                                campaignFlowStatus.campaignFlowCampaignInfo
                                  ?.campaignFlowCampaignId
                              }
                            </AgxBodyText>
                          </a>
                          <AgxDiagonalUpArrowIcon color="#318CE0" width={16} height={16} />
                        </AgxRow>
                      </td>
                      <td>
                        <AgxColumn fill smallGap>
                          {mapCampaignFlowStatus(
                            campaignFlowStatus.campaignFlowCampaignInfo
                              ?.campaignFlowCampaignStatus
                          )}
                          {campaignFlowStatus.campaignFlowCampaignInfo
                            ?.campaignFlowStatusInfo && (
                            <AgxBodyText
                              small
                              extraClasses="campaignFlowStatusInfo"
                            >
                              {
                                campaignFlowStatus.campaignFlowCampaignInfo
                                  ?.campaignFlowStatusInfo
                              }
                            </AgxBodyText>
                          )}
                        </AgxColumn>
                      </td>
                      <td>
                        <AgxBodyText
                          small
                          extraClasses="campaignMarketingAmount"
                        >
                          {formatCurrency(
                            campaignFlowStatus.marketingStatus
                              ?.totalMarketingAmount ?? null,
                            2
                          )}
                        </AgxBodyText>
                      </td>
                      <td>
                        <AgxBodyText
                          small
                          extraClasses="campaignMarketingAmount"
                        >
                          {formatCurrency(
                            campaignFlowStatus.marketingStatus
                              ?.amountVendorToPay ?? null,
                            2
                          )}
                        </AgxBodyText>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </AgxColumn>
            )}
        </>
      </ContentLayout>
    </PageLayout>
  )
}

export default CampaignMarketing
