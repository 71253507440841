import DeleteConfirmation from 'components/delete-confirmation/DeleteConfirmation'
import {
  AgxTextInput,
  AgxButton,
  AgxColumn,
  AgxRow,
  AgxToast,
  AgxPhoneNumberInput,
  enumToOptions,
  AgxSelect,
  AgxMultiSelect,
  AgxLabel,
  UploadedDocument,
} from '@urbanx/agx-ui-components'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { IStaff, Staff, StaffAction, StaffRole } from 'types/staff'
import { PlaceHolderImage } from 'components'
import { AgxToastState, NotificationState } from 'types/commonTypes'
import {
  CreateStaffMember,
  DeleteStaffMember,
  UpdateStaffMember,
  GetAllAgencies,
  SendInvitationEmail,
  UploadPublicDocument,
  DeleteLogo,
} from 'services'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { FormPrompt } from '../../components/FormPrompt'
import GetStaffMembersAssignedToAgencies, {
  GetUrbanXTeamByAgenciesRequest,
  StaffRoleAssignment,
  StaffRoleAssignmentsReponse,
} from 'services/functions/agency/getStaffMembersAssignedToAgencies'
import { Agency } from 'types/agency'
import isEqual from 'lodash/isEqual'
import RoleAssignmentModal from '../team/components/manage-staff/RoleAssignmentModel'
import {
  AssignStaffMemberToAgencies,
  GetAgenciesAssignedToStaff,
} from 'services'
import { ContactType } from 'services/functions/agency/sendInvitationEmail'
import FileUpload from 'components/file-upload/FileUpload'

type RemoveOptionalField<Type> = {
  [Property in keyof Type as Exclude<
    Property,
    'phoneNumber' | 'photo'
  >]: Type[Property]
}
type StaffMemberKeys = keyof IStaff
type StaffMemberRequiredKeys = keyof RemoveOptionalField<IStaff>

const requiredFields: StaffMemberRequiredKeys[] = [
  'email',
  'firstName',
  'lastName',
  'role',
  'title',
]

export interface EditStaffMemberProps {
  onBack: () => void
  staffMember: Staff
  loggedInStaffId: string
  pageAction: StaffAction
}

const staffRoleOptions = enumToOptions(StaffRole)

const rolesAssignableToAgency = [
  StaffRole.SalesAdmin,
  StaffRole.BusinessManager,
  StaffRole.ListingsAdmin,
  StaffRole.MarketingExpert,
]

const EditStaffMember = (props: EditStaffMemberProps) => {
  const { staffMember, onBack, loggedInStaffId, pageAction } = props
  const [emailInviteState, setEmailInviteState] = useState<NotificationState>(
    staffMember.emailInvitationSent
      ? NotificationState.Sent
      : NotificationState.NotSent
  )
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const queryClient = useQueryClient()
  const [toastState, updateToastState] = useState<AgxToastState>({
    color: 'success',
    message: '',
    open: false,
  })
  const [staffMemberForm, updateStaffMemberForm] = useState<Staff>(staffMember)
  const [, getAuthToken, userHasRequiredRoles] = useAzureAuth()
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [forceCheck, setForceCheck] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [img, updateImg] = useState(staffMember.photo?.publicPath)
  const [filteredStaffRoleAssignments, setFilteredStaffRoleAssignments] =
    useState<StaffRoleAssignment[]>([])

  const availableStaffRoleOptions = useMemo(() => {
    let availableRolesOptions = [...staffRoleOptions]

    if (
      !userHasRequiredRoles([StaffRole.SuperAdmin]) &&
      !userHasRequiredRoles([StaffRole.Executive])
    ) {
      availableRolesOptions = availableRolesOptions?.filter((role) => {
        const roleValue = role.value as StaffRole
        return (
          roleValue !== StaffRole.Executive &&
          roleValue !== StaffRole.SuperAdmin
        )
      })
    }

    if (
      !userHasRequiredRoles([StaffRole.SuperAdmin]) &&
      !userHasRequiredRoles([StaffRole.Executive]) &&
      !userHasRequiredRoles([StaffRole.BusinessManager])
    ) {
      availableRolesOptions = availableRolesOptions?.filter((role) => {
        const roleValue = role.value as StaffRole
        return roleValue !== StaffRole.BusinessManager
      })
    }

    return availableRolesOptions
  }, [userHasRequiredRoles])

  const queryKeys = {
    allStaffMembers: ['all-staff-members'],
    currentlyAssignedStaff: [] as (string | string[] | null)[],
    currentlyAssignedAgencies: [
      'currently-assigned-agencies',
      staffMemberForm.email,
      staffMemberForm.role ?? '',
    ],
    allAgencies: ['get-all-agencies'],
  }

  const { data: agencies, isSuccess: getAllAgenciesSuccess } = useQuery<
    Agency[] | undefined
  >({
    queryKey: queryKeys.allAgencies,
    queryFn: () => GetAllAgencies(getAuthToken),
  })

  const roleIsAssignableToAgency = useMemo(() => {
    return (
      staffMemberForm.role != null &&
      rolesAssignableToAgency.includes(staffMemberForm.role)
    )
  }, [staffMemberForm.role])

  const currentlyAssignedAgenciesKey = useMemo(() => {
    return [
      'currently-assigned-agencies',
      staffMemberForm.email,
      staffMemberForm.role ?? '',
    ]
  }, [staffMemberForm.email, staffMemberForm.role])

  const {
    data: staffMembersCurrentAgencies,
    refetch: refetchStaffMembersCurrentAgencies,
    isFetched: currentAgenciesIsFetched,
    isFetching: isFetchingCurrentAgencies,
  } = useQuery<string[] | undefined>({
    queryKey: currentlyAssignedAgenciesKey,
    queryFn: () =>
      GetAgenciesAssignedToStaff(
        staffMemberForm.email ?? '',
        staffMemberForm.role ?? '',
        getAuthToken
      ),
    enabled: roleIsAssignableToAgency,
  })

  useEffect(() => {
    setStaffAgencyIds(staffMembersCurrentAgencies ?? [])
  }, [staffMembersCurrentAgencies])

  useEffect(() => {
    refetchStaffMembersCurrentAgencies()
  }, [])

  const [agencyIds, setStaffAgencyIds] = useState<string[]>(
    staffMembersCurrentAgencies ?? []
  )
  queryKeys.currentlyAssignedStaff = [
    'currently-assigned-staff',
    staffMemberForm.role ?? '',
    agencyIds,
  ]

  function isEmailValid(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return emailRegex.test(email)
  }

  const { data: currentlyAssignedStaff, isFetched: currentStaffIsFetched } =
    useQuery<StaffRoleAssignmentsReponse | undefined>({
      queryKey: queryKeys.currentlyAssignedStaff,
      queryFn: () =>
        GetStaffMembersAssignedToAgencies({
          role: staffMemberForm.role ?? '',
          agencyIds,
          getAuthToken,
        } as GetUrbanXTeamByAgenciesRequest),
      enabled: roleIsAssignableToAgency,
    })

  const { mutate: createStaffMember } = useMutation(CreateStaffMember, {
    onSuccess: () => {
      setIsFormDirty(false)
      queryClient.invalidateQueries({ queryKey: queryKeys.allStaffMembers })
      assignStaffToAgencies({
        staffMemberId: staffMemberForm.email,
        staffRole: staffMemberForm.role,
        agencyIds: agencyIds,
        getAuthToken,
      })
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: 'Error saving changes',
        open: true,
      })
    },
  })

  const { mutate: updateStaffMember } = useMutation(UpdateStaffMember, {
    onSuccess: () => {
      setIsFormDirty(false)
      queryClient.invalidateQueries({ queryKey: queryKeys.allStaffMembers })
      onBack()
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: 'Error saving changes',
        open: true,
      })
    },
  })

  const { mutate: deleteStaffMember } = useMutation(DeleteStaffMember, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.allStaffMembers })
      onBack()
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: 'Error saving changes',
        open: true,
      })
    },
  })

  const { mutate: assignStaffToAgencies } = useMutation(
    AssignStaffMemberToAgencies,
    {
      onSuccess: () => {
        setIsFormDirty(false)
        queryClient.invalidateQueries({
          queryKey: queryKeys.currentlyAssignedAgencies,
        })
        queryClient.invalidateQueries({
          queryKey: queryKeys.currentlyAssignedStaff,
        })
        onBack()
      },
      onError: () => {
        updateToastState({
          color: 'error',
          message: 'Error occured assigning staff to agencies',
          open: true,
        })
      },
    }
  )

  const onDetailsChangeHandler = (
    value: string | boolean | string[] | UploadedDocument | undefined,
    prop: StaffMemberKeys
  ) => {
    if (staffMemberForm) {
      if (
        value &&
        JSON.stringify(staffMember[prop as keyof Staff]) ===
          JSON.stringify(value)
      )
        setIsFormDirty(false)
      else if (
        value &&
        JSON.stringify(staffMemberForm[prop as keyof Staff]) !==
          JSON.stringify(value)
      )
        setIsFormDirty(true)

      updateStaffMemberForm(() => {
        return { ...staffMemberForm, [prop]: value }
      })
    }
  }

  const validateForm = () => {
    const errors = requiredFields.reduce((acc, curr) => {
      if (!staffMemberForm) acc = [...requiredFields]
      if (
        staffMemberForm &&
        (staffMemberForm[curr] === '' ||
          staffMemberForm[curr] === undefined ||
          staffMemberForm[curr] === null)
      ) {
        acc.push(curr)
      }

      return acc
    }, [] as string[])

    return errors
  }

  const CheckCurrentRoleAssignments = () => {
    if (
      filteredStaffRoleAssignments &&
      filteredStaffRoleAssignments.length > 0
    ) {
      setShowWarningModal(true)
    } else if (
      (currentAgenciesIsFetched && currentStaffIsFetched) ||
      !roleIsAssignableToAgency
    ) {
      confirmStaffMemberUpdate()
    }
  }

  const onSaveChanges = async () => {
    const errors = validateForm()
    if (errors.length === 0) {
      if (pageAction === StaffAction.CREATE)
        createStaffMember({ getAuthToken, staff: staffMemberForm as Staff })
      else if ([StaffAction.SELF_EDIT, StaffAction.EDIT].includes(pageAction)) {
        CheckCurrentRoleAssignments()
      } else {
        // eslint-disable-next-line no-console
        console.error("There are errors on the 'Edit Staff Member' page")
      }
    }
  }

  const { mutate: sendEmailInvite } = useMutation(SendInvitationEmail, {
    onSuccess: () => {
      setEmailInviteState(NotificationState.Sent)
      queryClient.invalidateQueries({
        queryKey: [`agents-${staffMember.email}`],
      })
      updateToastState({
        color: 'success',
        message: 'Email Sent!',
        open: true,
      })
    },
    onError: () => {
      setEmailInviteState(NotificationState.NotSent)
      updateToastState({
        color: 'error',
        message: 'Error sending email invitation',
        open: true,
      })
    },
  })

  const handleEmailSent = () => {
    if (emailInviteState !== NotificationState.Sending) {
      setEmailInviteState(NotificationState.Sending)
      sendEmailInvite({
        id: staffMember.email || '',
        contactType: ContactType.Staff,
        getAuthToken,
      })
    }
  }

  const confirmStaffMemberUpdate = () => {
    assignStaffToAgencies({
      staffMemberId: staffMemberForm.email,
      staffRole: staffMemberForm.role,
      agencyIds: roleIsAssignableToAgency ? agencyIds : [],
      getAuthToken,
    })

    updateStaffMember({
      staff: staffMemberForm as Staff,
      getAuthToken,
    })
  }

  const confirmDeleteStaffMember = () => {
    deleteStaffMember({
      id: staffMemberForm.id,
      getAuthToken,
    })
  }

  const onBackClick = () => {
    isFormDirty ? setForceCheck(true) : onBack()
    queryClient.invalidateQueries({
      queryKey: queryKeys.currentlyAssignedAgencies,
    })
    queryClient.invalidateQueries({
      queryKey: queryKeys.currentlyAssignedStaff,
    })
  }

  const { mutate: uploadPublicDocument, isLoading: isUploading } = useMutation(
    UploadPublicDocument,
    {
      onSuccess: (value) => {
        updateImg(value?.publicPath || '')
        onDetailsChangeHandler(value as UploadedDocument, 'photo')
      },
    }
  )

  const uploadLogo = (file: File) => {
    updateImg(URL.createObjectURL(file))
    uploadPublicDocument({
      selectedFile: file,
      AgencyId: 'UrbanX',
      getAuthToken,
    })
  }
  const { mutate: deleteLogo } = useMutation(DeleteLogo, {
    onSuccess: () => {
      onDetailsChangeHandler(undefined, 'photo')
      queryClient.invalidateQueries({
        queryKey: queryKeys.allStaffMembers,
      })
      updateImg(undefined)
    },
  })

  useEffect(() => {
    if (currentlyAssignedStaff)
      setFilteredStaffRoleAssignments(
        currentlyAssignedStaff.staffRoleAssignments?.filter(
          (x) => x.staffMemberId !== staffMember.email
        )
      )
  }, [
    currentlyAssignedStaff,
    setFilteredStaffRoleAssignments,
    staffMember.email,
  ])

  useEffect(() => {
    if (pageAction === StaffAction.VIEW) onBack()
  }, [pageAction])

  const agenciesOptions = useMemo(() => {
    return getAllAgenciesSuccess
      ? agencies?.map((agency) => ({ label: agency.name, value: agency.id })) ??
          []
      : []
  }, [getAllAgenciesSuccess, agencies])

  if (!getAllAgenciesSuccess) return <AgxLabel>Loading</AgxLabel>

  return (
    <AgxColumn veryLargeGap>
      <FormPrompt
        hasUnsavedChanges={isFormDirty}
        forceCheck={forceCheck}
        resetForceCheck={(value: boolean) => setForceCheck(value)}
        onBack={onBack}
      />
      <AgxToast selector="#agxToast" toastState={toastState} />
      <AgxRow spaceBetween extraClasses="borderBottomContainer">
        <AgxButton
          text={`${
            pageAction === StaffAction.EDIT ? 'Edit' : 'Create'
          } Staff Member`}
          large
          naked
          onClick={() => onBackClick()}
        />
        <AgxRow veryLargeGap>
          {loggedInStaffId !== staffMember.id &&
            pageAction === StaffAction.EDIT && (
              <AgxButton
                text="Delete User"
                medium
                danger
                naked
                onClick={() => setShowDeleteConfirmation(true)}
              />
            )}
          <AgxButton
            text="Save Changes"
            medium
            primary
            onClick={() => onSaveChanges()}
            disabled={isUploading}
          />
        </AgxRow>
      </AgxRow>

      <AgxColumn veryLargeGap extraClasses="container50Percent">
        <AgxRow largeGap extraClasses="flexCentre" fill>
          {!img && (
            <PlaceHolderImage
              title={`${staffMember.firstName} ${staffMember.lastName}`}
              size={60}
            />
          )}
          {img && <img style={{ maxWidth: '150px' }} src={img} />}
          <div className="horizontalContainer noBorder">
            <FileUpload
              id="staffPhoto"
              title=""
              wide
              uploadFile={(file) => uploadLogo(file)}
              extensions={['jpg', 'jpeg', 'png', 'svg', 'bmp']}
            />
            <AgxButton
              text="Remove"
              medium
              hollow
              primary
              onClick={() => {
                deleteLogo({
                  FilePath: staffMemberForm.photo?.containerFilePath || '',
                  AgencyId: 'UrbanX',
                  getAuthToken,
                })
              }}
            />
          </div>
        </AgxRow>
        <AgxRow veryLargeGap>
          <AgxTextInput
            id="txtFirstName"
            label="First name"
            noOptionalLabel
            stretch
            parentControlValue
            defaultValue={staffMember.firstName}
            onInputValueChange={({ value }: { value: string }) =>
              onDetailsChangeHandler(value, 'firstName')
            }
          />
          <AgxTextInput
            id="txtLastName"
            label="Last name"
            noOptionalLabel
            stretch
            parentControlValue
            defaultValue={staffMember.lastName}
            onInputValueChange={({ value }: { value: string }) =>
              onDetailsChangeHandler(value, 'lastName')
            }
          />
        </AgxRow>
        <AgxRow>
          <AgxTextInput
            id="txtJobTitle"
            label="Job Title"
            noOptionalLabel
            stretch
            parentControlValue
            defaultValue={staffMember.title}
            onInputValueChange={({ value }: { value: string }) =>
              onDetailsChangeHandler(value, 'title')
            }
          />
        </AgxRow>
        <AgxRow>
          <AgxTextInput
            id="txtEmail"
            label="Email"
            noOptionalLabel
            stretch
            parentControlValue
            disabled={pageAction === StaffAction.EDIT}
            defaultValue={staffMember.email}
            onInputValueChange={({ value }: { value: string }) =>
              onDetailsChangeHandler(value, 'email')
            }
            email
          />
        </AgxRow>
        <AgxRow veryLargeGap>
          <AgxPhoneNumberInput
            id="txtPhone"
            label="Phone"
            stretch
            parentControlValue
            defaultValue={staffMember.phoneNumber}
            onInputValueChange={({ value }: { value: string }) =>
              onDetailsChangeHandler(value, 'phoneNumber')
            }
          />
        </AgxRow>
        <AgxRow>
          <AgxSelect
            id="selRole"
            label="Role"
            options={availableStaffRoleOptions}
            defaultValue={
              availableStaffRoleOptions.find(
                (opt) => opt.value === staffMember.role
              ) ?? availableStaffRoleOptions[0]
            }
            onValueChanged={({ value }: { value: string }) => {
              onDetailsChangeHandler(value, 'role')
            }}
            required
          />
        </AgxRow>
        {roleIsAssignableToAgency &&
          currentAgenciesIsFetched &&
          !isFetchingCurrentAgencies && (
            <AgxRow>
              <AgxMultiSelect
                id="selAgenciesForStaff"
                label="Agencies"
                hideOptionalLabel
                defaultValue={agencyIds}
                options={agenciesOptions}
                onValueChanged={({ value }: { value: string[] }) => {
                  if (!isEqual(agencyIds, value)) {
                    setStaffAgencyIds(value)
                    setIsFormDirty(true)
                  }
                }}
                parentControlValue
              />
            </AgxRow>
          )}
        {pageAction !== StaffAction.CREATE && (
          <AgxRow fill>
            <AgxColumn mediumGap>
              <AgxLabel medium>Email Invitation</AgxLabel>
              <AgxButton
                text={
                  emailInviteState !== NotificationState.Sending
                    ? `${
                        staffMember?.emailInvitationSent ||
                        emailInviteState === NotificationState.Sent
                          ? 'Resend'
                          : 'Send'
                      } Invite`
                    : 'Sending...'
                }
                medium
                primary
                disabled={
                  emailInviteState === NotificationState.Sending ||
                  !isEmailValid(staffMemberForm.email)
                }
                extraClasses={
                  emailInviteState === NotificationState.Sending
                    ? 'emailInviteButton'
                    : ''
                }
                onClick={handleEmailSent}
              />
            </AgxColumn>
          </AgxRow>
        )}
      </AgxColumn>

      <DeleteConfirmation
        name={`${staffMember.firstName} ${staffMember.lastName}`}
        title="Really delete user?"
        lineOne="Are you sure you want to delete user"
        lineTwo="This cannot be undone."
        primaryActionTitle="Permanently delete user"
        secondaryActionTitle="No, cancel"
        showPopUp={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={() => confirmDeleteStaffMember()}
      />

      {showWarningModal && (
        <RoleAssignmentModal
          setShowModal={setShowWarningModal}
          updateRoleAssignments={confirmStaffMemberUpdate}
          staffRoleAssignments={
            {
              staffRole: staffMemberForm.role,
              staffRoleAssignments: filteredStaffRoleAssignments,
            } as StaffRoleAssignmentsReponse
          }
        />
      )}
    </AgxColumn>
  )
}

export default EditStaffMember
