import { useNavigate, useParams } from 'react-router-dom'
import './Partner.scss'
import { PageLayout } from 'layout'
import { AgencyContext, UserContext } from 'contexts'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Agency } from 'types/agency'
import { useAzureAuth } from 'hooks/useAzureAuth'
import realEstateLogo from 'assets/images/Real-Estate-Logo.png'
import {
  StaffGetEntityNumbers,
  StaffGetAgencyPartnershipDetails,
  GetAllAgencies,
  StaffGetAllAgents,
  AgentDetailsProjection,
} from 'services'
import { ContractDateToUse, StaffGetEntityNumbersResponse } from 'types/rex'
import moment from 'moment'
import {
  AgxBodyText,
  AgxColumn,
  AgxDatePicker,
  AgxHeader,
  AgxMultiOptionButton,
  AgxRow,
  AgxSelect,
  EntityImage,
  EntityStatistics,
  Images,
  Option,
  PartnershipInfo,
} from '@urbanx/agx-ui-components'
import { PartnershipStatistics } from '@urbanx/agx-ui-components/dist/cjs/types/components/portal/Reporting/types'

const contractDateToUseOptions: Option[] = [
  {
    label: 'Written',
    value: ContractDateToUse.Written.toString(),
  },
  {
    label: 'Unconditional',
    value: ContractDateToUse.Unconditional.toString(),
  },
  {
    label: 'Settled',
    value: ContractDateToUse.Settled.toString(),
  },
]

const partnershipYearOptions: Option[] = [
  {
    label: 'This Year',
    value: '0',
  },
  {
    label: 'Last Year',
    value: '1',
  },
]

enum SelectedTab {
  Numbers,
  Partnership,
}

interface PartnerProps {
  individual?: boolean
}

const downloadCsv = (csvString: string, filename: string) => {
  const blob = new Blob([csvString], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.setAttribute('hidden', '')
  a.setAttribute('href', url)
  a.setAttribute('download', filename)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

const Partner = ({ individual = false }: PartnerProps) => {
  const user = useContext(UserContext)
  const navigate = useNavigate()
  const { entityId } = useParams()
  const [selectedAgency, setSelectedAgency] = useContext(AgencyContext)
  const [, getAuthToken] = useAzureAuth()
  const [contractDateToUse, setContractDateToUse] = useState<ContractDateToUse>(
    ContractDateToUse.Written
  )
  const [fromDate, setFromDate] = useState<string>(
    moment().startOf('month').format('YYYY-MM-DD')
  )
  const [toDate, setToDate] = useState<string>(moment().format('YYYY-MM-DD'))

  const [selectedTab, setSelectedTab] = useState<SelectedTab>(
    SelectedTab.Numbers
  )

  const [selectedPartnershipYear, setSelectedPartnershipYear] = useState(
    partnershipYearOptions[0]
  )

  const { data: agencies } = useQuery<Agency[] | undefined>({
    queryKey: ['all-agencies'],
    queryFn: () => GetAllAgencies(getAuthToken),
  })

  const { data: agents } = useQuery<AgentDetailsProjection[] | undefined>({
    queryKey: ['all-agents'],
    queryFn: () => StaffGetAllAgents(getAuthToken),
  })

  const selectedAgent = useMemo(() => {
    return agents?.find((a) => a.id === entityId)
  }, [agents, entityId])

  useEffect(() => {
    if (individual) {
      if (!selectedAgent) return
      setSelectedAgency(agencies?.find((a) => a.id === selectedAgent.agencyId))
    } else {
      setSelectedAgency(agencies?.find((a) => a.id === entityId))
    }
  }, [agencies, individual, selectedAgent])

  const { data: partnershipNumbers, isLoading: partnershipNumbersLoading } =
    useQuery<StaffGetEntityNumbersResponse | undefined>({
      queryKey: [
        'staffGetEntityNumbers',
        entityId,
        contractDateToUse,
        fromDate,
        toDate,
        individual,
      ],
      queryFn: (queryKey) => StaffGetEntityNumbers(queryKey, getAuthToken),
    })

  const {
    data: agencyPartnershipStatistics,
    isLoading: agencyPartnershipStatisticsLoading,
  } = useQuery<PartnershipStatistics | undefined>({
    queryKey: [`agencyPartnershipStatistics-${entityId}`, selectedAgency?.id],
    queryFn: (queryInfo) =>
      StaffGetAgencyPartnershipDetails(queryInfo, getAuthToken),
    onError: (error) => {
      console.error(error)
    },
    enabled: !!selectedAgency,
  })

  const exportToCSV = useCallback(() => {
    let csvString = ''
    const title = partnershipNumbers?.title?.replaceAll(' ', '_') || 'export'
    let filename = title

    if (selectedTab === SelectedTab.Numbers) {
      filename = `${title}_numbers_${fromDate}_to_${toDate}.csv`

      csvString =
        `Sales Volume,Amount above Sales Volume Goal,` +
        `GCI,Amount above GCI goal,` +
        `Listings,Amount above listings goal,` +
        `Sales,Amount above sales goal,` +
        `Conversion Rate,Amount above conversion rate goal,` +
        `Average Sale Price,Amount above average sale price goal,` +
        `Average Commission Rate,Amount above average commission rate goal\n`
      csvString +=
        `${partnershipNumbers?.statistics?.salesVolume},${partnershipNumbers?.statistics?.amountSalesVolumeAboveTarget},` +
        `${partnershipNumbers?.statistics?.grossCommissionIncome},${partnershipNumbers?.statistics?.amountGrossCommissionIncomeAboveTarget},` +
        `${partnershipNumbers?.statistics?.listings},${partnershipNumbers?.statistics?.amountListingsAboveTarget},` +
        `${partnershipNumbers?.statistics?.sales},${partnershipNumbers?.statistics?.amountSalesAboveTarget},` +
        `${partnershipNumbers?.statistics?.conversionRate},${partnershipNumbers?.statistics?.amountConversionRateAboveTarget},` +
        `${partnershipNumbers?.statistics?.averageSalePrice},${partnershipNumbers?.statistics?.amountAverageSalePriceAboveTarget},` +
        `${partnershipNumbers?.statistics?.averageCommissionRate},${partnershipNumbers?.statistics?.amountAverageCommissionRateAboveTarget}\n`
    } else if (selectedTab === SelectedTab.Partnership) {
      filename = `${title}_partnership_${selectedPartnershipYear.label.replaceAll(
        ' ',
        '_'
      )}.csv`

      const relevantStats =
        selectedPartnershipYear === partnershipYearOptions[0]
          ? agencyPartnershipStatistics?.thisYear
          : agencyPartnershipStatistics?.lastYear

      csvString =
        `Sales Volume,Amount above Sales Volume Goal,` +
        `GCI,Amount above GCI goal,` +
        `Listings,Amount above listings goal,` +
        `Sales,Amount above sales goal,` +
        `Conversion Rate,Amount above conversion rate goal,` +
        `Average Sale Price,Amount above average sale price goal,` +
        `Average Commission Rate,Amount above average commission rate goal\n`

      csvString +=
        `${relevantStats?.salesVolume},${relevantStats?.amountSalesVolumeAboveTarget},` +
        `${relevantStats?.grossCommissionIncome},${relevantStats?.amountGrossCommissionIncomeAboveTarget},` +
        `${relevantStats?.listings},${relevantStats?.amountListingsAboveTarget},` +
        `${relevantStats?.sales},${relevantStats?.amountSalesAboveTarget},` +
        `${relevantStats?.conversionRate},${relevantStats?.amountConversionRateAboveTarget},` +
        `${relevantStats?.averageSalePrice},${relevantStats?.amountAverageSalePriceAboveTarget},` +
        `${relevantStats?.averageCommissionRate},${relevantStats?.amountAverageCommissionRateAboveTarget}\n`
    }

    downloadCsv(csvString, filename)
  }, [partnershipNumbers, selectedTab])

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      currentPageTitle="Partner"
      agencyName={partnershipNumbers?.title || ''}
      agencyLogoUrl={realEstateLogo}
    >
      <AgxColumn fill extraClasses="partnerPage">
        <AgxColumn fill>
          <AgxRow fill centered spaceBetween extraClasses="partnerPageHeader">
            <AgxRow extraClasses="partnerPageHeader-topLeft" centered>
              <Images.ArrowBackOutline onClick={() => navigate('/partners')} />
              <AgxRow
                extraClasses="partnerPageHeader-topLeft-entityTitle"
                centered
              >
                <EntityImage
                  addRingToImage={individual}
                  backgroundColour={selectedAgency?.backgroundColor}
                  name={partnershipNumbers?.title}
                  photoUri={
                    individual
                      ? selectedAgent?.photoUri
                      : selectedAgency?.logoUrl
                  }
                />
                <AgxHeader size={5}>{partnershipNumbers?.title}</AgxHeader>
              </AgxRow>
            </AgxRow>
            <AgxRow extraClasses="partnerPageHeader-topRight" centered>
              {selectedTab == SelectedTab.Numbers && (
                <AgxSelect
                  id="contractDateToUse"
                  options={contractDateToUseOptions}
                  onValueChanged={({ value }) => {
                    setContractDateToUse(parseInt(value) as ContractDateToUse)
                  }}
                  defaultValue={
                    contractDateToUseOptions.find(
                      (c) => c.value === contractDateToUse.toString()
                    ) ?? contractDateToUseOptions[0]
                  }
                  noHeader
                  hideOptionalLabel
                />
              )}
              {selectedTab == SelectedTab.Numbers && (
                <AgxRow centered extraClasses="partnerPageHeader-dateFilters">
                  <AgxDatePicker
                    id="fromDate"
                    defaultValue={fromDate}
                    date
                    noOptionalLabel
                    noHeader
                    onValueChanged={({ value }) => setFromDate(value)}
                    stretch
                  />
                  <AgxBodyText small>to</AgxBodyText>
                  <AgxDatePicker
                    id="toDate"
                    defaultValue={toDate}
                    date
                    noOptionalLabel
                    noHeader
                    onValueChanged={({ value }) => setToDate(value)}
                    stretch
                  />
                </AgxRow>
              )}
              {selectedTab == SelectedTab.Partnership && (
                <AgxSelect
                  id="partnershipYearSelector"
                  options={partnershipYearOptions}
                  onValueChanged={({ value }) =>
                    setSelectedPartnershipYear(
                      partnershipYearOptions.find(
                        (option) => option.value === value
                      ) ?? partnershipYearOptions[0]
                    )
                  }
                  defaultValue={selectedPartnershipYear}
                  noHeader
                  hideOptionalLabel
                />
              )}
              <AgxMultiOptionButton
                primary
                medium
                options={[
                  {
                    text: 'PDF file',
                    onClick: () => {
                      window.print()
                    },
                  },
                  {
                    text: 'CSV file',
                    onClick: exportToCSV,
                  },
                ]}
                text="Export"
              />
            </AgxRow>
          </AgxRow>
          <AgxRow extraClasses="partnerPageTabs">
            <AgxHeader
              size={5}
              strong={selectedTab === SelectedTab.Numbers}
              onClick={() => setSelectedTab(SelectedTab.Numbers)}
            >
              Numbers
            </AgxHeader>
            <AgxHeader
              size={5}
              strong={selectedTab === SelectedTab.Partnership}
              onClick={() => setSelectedTab(SelectedTab.Partnership)}
            >
              Partnership
            </AgxHeader>
          </AgxRow>
        </AgxColumn>
        {selectedTab === SelectedTab.Numbers && (
          <EntityStatistics
            loading={partnershipNumbersLoading}
            personalStatistics={partnershipNumbers?.statistics}
          />
        )}
        {selectedTab === SelectedTab.Partnership && (
          <>
            <PartnershipInfo
              partnershipStats={agencyPartnershipStatistics}
              isCurrentYear={
                selectedPartnershipYear === partnershipYearOptions[0]
              }
              forceDarkerColours
            />
            <EntityStatistics
              loading={agencyPartnershipStatisticsLoading}
              personalStatistics={
                selectedPartnershipYear === partnershipYearOptions[0]
                  ? agencyPartnershipStatistics?.thisYear
                  : agencyPartnershipStatistics?.lastYear
              }
            />
          </>
        )}
      </AgxColumn>
    </PageLayout>
  )
}

export default Partner
