import { AgencyCode } from './agencyCode'

export interface CampaignFlow extends AgencyCode {
  code: string
}

export interface CampaignStatusModel {
  campaignId: string
  marketingStatus?: CampaignMarketingStatus
  campaignFlowCampaignInfo?: CampaignFlowCampaignInfo
}

export interface CampaignMarketingStatus {
  marketingPackageName?: string
  totalMarketingAmount?: number
  amountVendorToPay?: number
}

export interface CampaignFlowCampaignInfo {
  campaignFlowCampaignId?: number
  campaignFlowCampaignUrl?: string
  campaignFlowCampaignStatus?: CampaignFlowCampaignStatus
  campaignFlowStatusInfo?: string
}

export enum CampaignFlowCampaignStatus
{
    AwaitingFunds,
    PayLaterApplicationSigned,
    Paid,
    Cancelled,
    PendingSettlement,
    Complete,
    Withdrawn,
    PayLaterDeclined
}