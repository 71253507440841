import { useContext, useEffect, useState, useMemo } from 'react'
import { MenuTitles } from '../../constants/menu'
import { AgencyContext, UserContext } from '../../contexts'
import { ContentLayout, PageLayout } from '../../layout'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import {
  getMarketingPackagesForStaff,
  getMarketingItemsForStaff,
  RemoveMarketingPackage,
  GetAllAgencies,
  GetAgentsInAgency,
  updateMarketingPackageOrder,
  AddMarketingPackage,
} from 'services'
import { Agency, Agent, MarketingPackage, MarketingItem } from 'types/agency'
import {
  AgxButton,
  AgxRow,
  AgxColumn,
  AgxBodyText,
  AgxHeader,
  Images,
} from '@urbanx/agx-ui-components'
import DeleteConfirmation from '../../components/delete-confirmation/DeleteConfirmation'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd'
import EditMarketingPackage from './components/edit-marketing-package/EditMarketingPackage'
import EditItems from './components/edit-items/EditItems'
import './marketing.css'
import { useAzureAuth } from 'hooks/useAzureAuth'

const MarketingPage = () => {
  const { agencyId } = useParams()
  const queryClient = useQueryClient()
  const user = useContext(UserContext)
  const [selectedAgency, setSelectedAgency] = useContext(AgencyContext)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showMoreOptionsId, setShowMoreOptionsId] = useState('')
  const [showDetails, setShowDetails] = useState(false)
  const [showEditItems, setShowEditItems] = useState(false)
  const [marketingPackages, setMarketingPackages] = useState<
    MarketingPackage[]
  >([])
  const [selectedPackage, setSelectedPackage] = useState<
    MarketingPackage | undefined
  >(undefined)
  const [, getAuthToken] = useAzureAuth()

  const { data: agencies } = useQuery<Agency[] | undefined>({
    queryKey: ['all-agencies'],
    queryFn: () => GetAllAgencies(getAuthToken),
    enabled: !selectedAgency,
  })

  const { data } = useQuery<MarketingPackage[] | undefined>({
    queryKey: [`packages-${agencyId}`, agencyId],
    queryFn: (queryKey) => getMarketingPackagesForStaff(queryKey, getAuthToken),
  })

  const { data: agents } = useQuery<Agent[] | undefined>({
    queryKey: [`agentsInAgency-${agencyId}`, agencyId],
    queryFn: (queryKey) => GetAgentsInAgency(queryKey, getAuthToken),
  })

  const { mutate: addPackage } = useMutation(AddMarketingPackage, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`packages-${agencyId}`, agencyId],
      })
    },
  })

  const { mutate: updateMarketingPkgOrder } = useMutation(
    updateMarketingPackageOrder,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [`packages-${agencyId}`, agencyId],
        })
      },
    }
  )

  const { mutate: removePackage } = useMutation(RemoveMarketingPackage, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`packages-${agencyId}`, agencyId],
      })
    },
  })

  const { data: marketingItems } = useQuery<MarketingItem[] | undefined>({
    queryKey: [`packageItem-${agencyId}`, agencyId],
    queryFn: (queryKey) => getMarketingItemsForStaff(queryKey, getAuthToken),
  })

  useEffect(() => {
    if (data) setMarketingPackages(data)
  }, [data])

  useEffect(() => {
    if (!selectedAgency) {
      setSelectedAgency(agencies?.find((a) => a.id === agencyId))
    }
  }, [agencies])

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [`agentsInAgency-${agencyId}`, agencyId],
    })
  }, [])

  const onEditMarketingPackage = (mp: MarketingPackage) => {
    setSelectedPackage(mp)
    setShowDetails(true)
  }

  const onRemovePackageClick = (mp: MarketingPackage) => {
    setSelectedPackage(mp)
    setShowDeleteConfirmation(true)
  }

  const onDuplicatePackageClick = (mp: MarketingPackage) => {
    addPackage({
      AgencyId: agencyId || '',
      Name: `${mp.name} - Copy`,
      DisplayName: mp.displayName,
      IncludeRealEstateListing: mp.includeRealEstateListing,
      IncludeDomainListing: mp.includeDomainListing,
      DisplayItemisedPricingOnPdf: mp.displayItemisedPricingOnPdf,
      Items: mp.items,
      AddOns: mp.addOns,
      specialConditions: mp.specialConditions,
      assignedAgentIds: mp.assignedAgentIds,
      getAuthToken,
    })
    setShowMoreOptionsId('')
  }

  const onConfirmDelete = () => {
    removePackage({
      AgencyId: selectedAgency?.id || '',
      MarketingPackageId: selectedPackage?.id || '',
      getAuthToken,
    })
    setShowDeleteConfirmation(false)
  }

  const onAddNewPackageClick = () => {
    setSelectedPackage(undefined)
    setShowDetails(true)
  }

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return
    if (marketingPackages && marketingPackages?.length > 0) {
      const items = [...marketingPackages]
      const [reorderedItem] = items.splice(result.source.index, 1)
      items.splice(result.destination.index, 0, reorderedItem)

      const marketingPackageIds = items.map((item) => item.id)

      setMarketingPackages(items)
      updateMarketingPkgOrder({
        AgencyId: agencyId || '',
        marketingPackageIds,
        getAuthToken,
      })
    }
  }

  const onEditItems = () => {
    setShowEditItems(true)
  }

  const renderMarketingPackages = (mp: MarketingPackage, index: number) => {
    return (
      <Draggable key={mp.id} draggableId={mp.id} index={index}>
        {(provided) => (
          <li
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <AgxRow spaceBetween extraClasses="borderBottomContainer">
              <div className="thPackageNameContainer__marketing">
                <Images.DragDropIcon />
                <AgxHeader size={4}>{mp.name}</AgxHeader>
              </div>
              <div className="actionContainerTD">
                <AgxButton
                  text="Manage"
                  medium
                  hollow
                  onClick={() => onEditMarketingPackage(mp)}
                />
                <div className="moreOptions">
                  <AgxButton
                    text=""
                    large
                    naked
                    onClick={() => {
                      showMoreOptionsId !== mp.id
                        ? setShowMoreOptionsId(mp.id)
                        : setShowMoreOptionsId('')
                    }}
                  />
                  {showMoreOptionsId.length > 0 &&
                    showMoreOptionsId === mp.id && (
                      <div className="moreOptionsContent">
                        <div onClick={() => onDuplicatePackageClick(mp)}>
                          Duplicate
                        </div>
                        <div onClick={() => onRemovePackageClick(mp)}>
                          Delete
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </AgxRow>
          </li>
        )}
      </Draggable>
    )
  }

  const activeAgents = useMemo(() => {
    return agents?.filter((a) => !a.softDeleted) || []
  }, [agents])

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={selectedAgency?.name || ''}
      currentPageTitle="Marketing Packages"
    >
      <ContentLayout
        hasSideMenu={true}
        activeMenu={MenuTitles.MARKETING_PACKAGES}
      >
        <AgxRow extraClasses="stretchContainerItems">
          {showEditItems && (
            <EditItems
              onBack={() => setShowEditItems(false)}
              existingItems={marketingItems?.map((item) => ({ ...item })) || []}
              agencyId={selectedAgency?.id || ''}
            />
          )}
          {showDetails && (
            <EditMarketingPackage
              onBack={() => setShowDetails(false)}
              marketingPackage={selectedPackage}
              includedStandardInclusions={selectedPackage?.items || []}
              includedAddons={selectedPackage?.addOns || []}
              allItems={marketingItems || []}
              allAvailableAgents={activeAgents || []}
              agencyId={selectedAgency?.id || ''}
            />
          )}
          {!showEditItems && !showDetails && (
            <AgxRow fill veryLargeGap>
              <AgxColumn fill extraClasses="sectionStyle">
                <AgxHeader size={2}>{marketingItems?.length}</AgxHeader>
                <AgxColumn extraClasses="borderBottomContainer noBorder">
                  <AgxBodyText large>Marketing Items</AgxBodyText>
                </AgxColumn>
                <AgxRow>
                  <AgxButton
                    text="Edit Items"
                    medium
                    hollow
                    onClick={() => onEditItems()}
                  />
                </AgxRow>
              </AgxColumn>
              <AgxColumn fill extraClasses="sectionStyle">
                <AgxHeader size={2}>{marketingPackages?.length}</AgxHeader>
                <AgxColumn extraClasses="borderBottomContainer">
                  <AgxBodyText large>Marketing Packages</AgxBodyText>
                </AgxColumn>

                <DragDropContext
                  onDragEnd={(result) => handleOnDragEnd(result)}
                >
                  <Droppable droppableId="marketingPackages">
                    {(provided) => (
                      <ul
                        className="marketingPackagesList"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {marketingPackages &&
                          marketingPackages.map((mp, index) =>
                            renderMarketingPackages(mp, index)
                          )}

                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>

                <AgxRow>
                  <AgxButton
                    text="New Package"
                    medium
                    hollow
                    onClick={() => onAddNewPackageClick()}
                  />
                </AgxRow>
              </AgxColumn>
            </AgxRow>
          )}

          <DeleteConfirmation
            name={selectedPackage?.name || ''}
            title="Delete marketing package?"
            lineOne="Are you sure you want to delete"
            lineTwo="This cannot be undone."
            primaryActionTitle="Permanently delete package"
            secondaryActionTitle="No, cancel"
            showPopUp={showDeleteConfirmation}
            onClose={() => setShowDeleteConfirmation(false)}
            onConfirm={() => onConfirmDelete()}
          />
        </AgxRow>
      </ContentLayout>
    </PageLayout>
  )
}

export default MarketingPage
