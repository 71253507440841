import { QueryKey } from '@tanstack/react-query'
import CampaignFlowService from 'services/campaignFlowService'
import { CampaignStatusModel } from 'types/campaignFlow'

const getCampaignStatus = async (
  { queryKey }: { queryKey: QueryKey },
  getAuthToken: () => Promise<string | void>
): Promise<CampaignStatusModel | undefined> => {
  const token = await getAuthToken()
  if (!token) return undefined

  const campaignId = queryKey[1] as string
  const service = new CampaignFlowService(token)

  if (!service) return undefined

  const result = await service.campaignFlowService.get('GetCampaignStatus', {
    campaignId,
  })

  return result.data
}

export default getCampaignStatus
