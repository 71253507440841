interface Props {
  height?: string
  width?: string
  padding?: string
  color?: string
  opacity?: number
  extraClasses?: string
}

const ArrowUpIcon = (props: Props) => {
  const {
    height = '6',
    width = '10',
    color = '#FFFFFF',
    padding = '1em',
    opacity = 1,
    extraClasses = '',
  } = props

  return (
    <div style={{ padding: `${padding}`, opacity }} className={extraClasses}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.33333 5.08331C8.22366 5.08394 8.11494 5.06292 8.01341 5.02145C7.91188 4.97998 7.81953 4.91888 7.74166 4.84164L5 2.08331L2.25 4.73331C2.09386 4.88852 1.88265 4.97563 1.6625 4.97563C1.44234 4.97563 1.23113 4.88852 1.075 4.73331C0.99689 4.65584 0.934895 4.56367 0.892588 4.46212C0.850281 4.36057 0.828499 4.25165 0.828499 4.14164C0.828499 4.03163 0.850281 3.92271 0.892588 3.82116C0.934895 3.71961 0.99689 3.62744 1.075 3.54997L4.40833 0.333307C4.5641 0.180618 4.77354 0.0950928 4.99166 0.0950928C5.20979 0.0950928 5.41922 0.180618 5.575 0.333307L8.90833 3.66664C8.98644 3.74411 9.04843 3.83628 9.09074 3.93783C9.13305 4.03938 9.15483 4.1483 9.15483 4.25831C9.15483 4.36832 9.13305 4.47724 9.09074 4.57879C9.04843 4.68034 8.98644 4.7725 8.90833 4.84997C8.75385 4.99887 8.54789 5.08245 8.33333 5.08331Z"
          fill={color}
        />
      </svg>
    </div>
  )
}
export default ArrowUpIcon
