import { useEffect, useState } from 'react'
import { Team, TeamMember } from '../../../../types/agency'
import { AgxButton, AgxColumn, AgxRow } from '@urbanx/agx-ui-components'
import { v4 as uuidv4 } from 'uuid'

import './manageTeams.css'
import AddTeam from './AddTeam'

interface ManageTeamsProps {
  agencyName: string
  teams: Team[]
  agents: TeamMember[]
  showPopUp: boolean
  onBackClick: () => void
  onSaveChanges: (teams: Team[]) => void
}

const ManageTeams = (props: ManageTeamsProps) => {
  const { agents, teams, onBackClick, onSaveChanges } = props
  const [newTeams, setNewTeams] = useState<Team[]>([])
  const addNewTeam = () => {
    setNewTeams([{ id: uuidv4(), name: '', members: [] }, ...newTeams])
  }

  const updateTeamName = (id: string, value: string) => {
    const updatedArray = newTeams.map((team) => {
      if (team.id === id) {
        team.name = value
      }
      return team
    })
    setNewTeams(updatedArray)
  }

  const updateTeamPhoto = (id: string, photoUri: string) => {
    const updatedArray = newTeams.map((team) => {
      if (team.id === id) {
        team.photoUri = photoUri ? photoUri : null
      }
      return team
    })
    setNewTeams(updatedArray)
  }

  const updateTeamMembers = (id: string, value: string) => {
    if (id && value) {
      const memberIds = value.split(',')
      const updatedTeam = newTeams.find((team) => team.id === id)
      if (updatedTeam) {
        updatedTeam.members = [...memberIds]
      }
    }
  }

  const onDeleteTeamHandler = (id: string) => {
    setNewTeams(newTeams.filter((t) => t.id !== id))
  }

  useEffect(() => {
    if (teams.length > 0) {
      // /**
      //  * Get All Agents that belongs to selected agency
      //  * (This will be needed to populate team members dropdown)
      //  */
      // const allAgents: TeamMember[] = teams.reduce((acc, curr) => {
      //   const uniqueMembers = curr.members.filter(
      //     (member) =>
      //       !acc.find((existingMember) => existingMember.id === member.id)
      //   )
      //   acc = [...acc, ...uniqueMembers]
      //   return acc
      // }, [] as TeamMember[])

      // setAgents([...allAgents])

      /**
       * Prefill any existing teams to newTeams array
       * (this will be needed to pre-populate data)
       */

      setNewTeams([...teams])
    }
  }, [teams])

  return (
    <AgxColumn extraLargeGap>
      <AgxRow spaceBetween extraClasses="borderBottomContainer">
        <AgxButton
          text={'Manage Teams'}
          large
          naked
          onClick={() => onBackClick()}
        />
        <AgxButton
          text="Save Changes"
          medium
          primary
          onClick={() => onSaveChanges(newTeams)}
        />
      </AgxRow>
      <AgxColumn extraLargeGap>
        <AgxRow>
          <AgxButton
            text="Add Team"
            medium
            hollow
            onClick={() => addNewTeam()}
          />
        </AgxRow>
        {newTeams.map((team, index) => (
          <AddTeam
            key={team.id}
            {...team}
            agents={agents}
            index={index + 1}
            updateTeamName={updateTeamName}
            updateTeamPhoto={updateTeamPhoto}
            updateTeamMembers={updateTeamMembers}
            onDeleteTeamHandler={onDeleteTeamHandler}
          />
        ))}
      </AgxColumn>
    </AgxColumn>
  )
}

export default ManageTeams
