import { AgxRow } from '@urbanx/agx-ui-components'
import './MobileHeader.scss'
import AutoPilotIcon from 'components/sidebar/icons/autopilot'

interface Props {
  isMenuCollapsed: boolean
  setIsMenuCollapsed: (value: boolean) => void
}

const MobileHeader = (props: Props) => {
  const { isMenuCollapsed, setIsMenuCollapsed } = props
  return (
    <AgxRow fill extraClasses="mobileHeaderContainer" centered spaceBetween>
      <AutoPilotIcon />
      <div
        className={`toggleMenuBtn ${isMenuCollapsed ? '' : 'open'}`}
        onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}
      >
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </AgxRow>
  )
}

export default MobileHeader
