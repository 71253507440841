interface Props {
  height?: string
  width?: string
  color?: string
  padding?: string
  opacity?: number
}

const ReportingIcon = (props: Props) => {
  const {
    height = '20',
    width = '20',
    color = '#FFFFFF',
    padding = '1em',
    opacity = 1,
  } = props

  return (
    <div style={{ padding: `${padding}`, opacity }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.8333 1.66669C10.6123 1.66669 10.4004 1.75448 10.2441 1.91076C10.0878 2.06704 10 2.27901 10 2.50002V9.16669C10 9.3877 10.0878 9.59966 10.2441 9.75594C10.4004 9.91222 10.6123 10 10.8333 10H17.5C17.721 10 17.933 9.91222 18.0893 9.75594C18.2455 9.59966 18.3333 9.3877 18.3333 9.16669C18.3333 7.17756 17.5432 5.26991 16.1366 3.86339C14.7301 2.45686 12.8225 1.66669 10.8333 1.66669ZM11.6667 8.33335V3.39169C12.9135 3.57136 14.0687 4.14983 14.9594 5.04059C15.8502 5.93134 16.4287 7.08652 16.6083 8.33335H11.6667Z"
          fill={color}
        />
        <path
          d="M17.35 11.7167C17.2466 11.6797 17.1369 11.6635 17.0273 11.6691C16.9176 11.6747 16.8101 11.7019 16.711 11.7491C16.6119 11.7963 16.5231 11.8627 16.4497 11.9444C16.3763 12.026 16.3197 12.1214 16.2833 12.225C15.9078 13.2866 15.2695 14.2358 14.4279 14.984C13.5863 15.7322 12.569 16.255 11.4707 16.5037C10.3724 16.7524 9.22908 16.7189 8.14729 16.4062C7.0655 16.0935 6.08053 15.512 5.28428 14.7157C4.48803 13.9195 3.90645 12.9345 3.59379 11.8527C3.28113 10.7709 3.24758 9.62755 3.49628 8.52929C3.74498 7.43103 4.26782 6.41366 5.01602 5.5721C5.76422 4.73054 6.71339 4.09221 7.77499 3.71667C7.98385 3.64373 8.15518 3.49082 8.25129 3.29156C8.34741 3.0923 8.36043 2.86303 8.28749 2.65417C8.21456 2.44531 8.06164 2.27398 7.86239 2.17787C7.66313 2.08175 7.43385 2.06873 7.22499 2.14167C5.89753 2.61065 4.71052 3.4083 3.77476 4.46018C2.83899 5.51206 2.18498 6.78388 1.87376 8.15692C1.56254 9.52997 1.60426 10.9595 1.99503 12.312C2.38579 13.6646 3.11286 14.8961 4.10838 15.8916C5.1039 16.8871 6.3354 17.6142 7.68796 18.005C9.04052 18.3957 10.47 18.4375 11.8431 18.1262C13.2161 17.815 14.4879 17.161 15.5398 16.2252C16.5917 15.2895 17.3893 14.1025 17.8583 12.775C17.9304 12.5672 17.9174 12.3394 17.8222 12.1411C17.727 11.9429 17.5572 11.7903 17.35 11.7167Z"
          fill={color}
        />
      </svg>
    </div>
  )
}
export default ReportingIcon
