interface Props {
  height?: string
  width?: string
  color?: string
  padding?: string
  opacity?: number
}

const UrbanXIcon = (props: Props) => {
  const {
    height = '20',
    width = '20',
    color = '#FFFFFF',
    padding = '1em',
    opacity = 1,
  } = props

  return (
    <div style={{ padding: `${padding}`, opacity }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.9531 0.0265025L10.0407 7.64112L6.5539 2.67621C6.31923 2.30143 6.07755 2.0545 5.83236 1.93716C5.58543 1.81983 5.15812 1.76028 4.54692 1.76028H2.962C1.78863 1.76028 1.50667 2.18234 2.11787 3.02822L7.50485 10.6341L1.87094 18.7321C1.18969 19.5762 1.45939 20 2.68179 20H4.33676C4.92345 20 5.335 19.9475 5.56967 19.8424C5.80434 19.7373 6.05128 19.4851 6.30872 19.0858L9.93565 13.557L13.7377 19.0858C13.9951 19.4851 14.2421 19.7373 14.4768 19.8424C14.7114 19.9475 15.1212 20 15.7097 20H17.3997C18.5257 20 18.7832 19.5779 18.1737 18.7321L12.5398 10.5991L17.0844 0.112316C17.1194 0.0300054 17.0126 -0.0400466 16.9531 0.0265025Z"
          fill={color}
        />
      </svg>
    </div>
  )
}
export default UrbanXIcon
