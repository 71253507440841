import React from 'react'
import {
  AgxBodyText,
  AgxButton,
  AgxColumn,
  AgxHeader,
  AgxSlideUpModal,
  Images,
} from '@urbanx/agx-ui-components'

interface Props {
  errorList: string[]
  setShowModal: (showModal: boolean) => void
}

export const ErrorModal = (props: Props) => {
  const { errorList, setShowModal } = props
  const errorContent = (
    <AgxColumn veryLargeGap veryLargeSidePadding>
      <Images.AlertCircleOutline />
      <AgxHeader size={3}>Your contract is missing required fields</AgxHeader>
      <AgxBodyText>
        Complete required fields on this form before switching the contract
        live:
      </AgxBodyText>
      <AgxBodyText>
        <AgxColumn smallGap>
          {errorList &&
            errorList.map((error, idx) => <li key={idx}>{error}</li>)}
        </AgxColumn>
      </AgxBodyText>
    </AgxColumn>
  )

  return (
    <AgxSlideUpModal
      closeButton
      content={errorContent}
      desktop={true}
      actionButtons={
        <AgxButton
          primary
          wide
          large
          text="Okay"
          onClick={() => setShowModal(false)}
        />
      }
      onClose={() => setShowModal(false)}
    />
  )
}

export default ErrorModal
