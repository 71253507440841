interface Props {
  height?: string
  width?: string
  padding?: string
  color?: string
  opacity?: number
}

const AgenciesIcon = (props: Props) => {
  const {
    height = '20',
    width = '20',
    color = '#FFFFFF',
    padding = '1em',
    opacity = 1,
  } = props

  return (
    <div style={{ padding: `${padding}`, opacity }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.33334 4.16669C3.33334 2.78598 4.45263 1.66669 5.83334 1.66669H14.1667C15.5474 1.66669 16.6667 2.78598 16.6667 4.16669V16.6667H17.5C17.9602 16.6667 18.3333 17.0398 18.3333 17.5C18.3333 17.9603 17.9602 18.3334 17.5 18.3334H16.6667H10.8333H9.16667H3.33334H2.50001C2.03977 18.3334 1.66667 17.9603 1.66667 17.5C1.66667 17.0398 2.03977 16.6667 2.50001 16.6667H3.33334V4.16669ZM5.00001 16.6667H7.50001V15C7.50001 14.0795 8.2462 13.3334 9.16667 13.3334H10.8333C11.7538 13.3334 12.5 14.0795 12.5 15V16.6667H15V4.16669C15 3.70645 14.6269 3.33335 14.1667 3.33335H5.83334C5.3731 3.33335 5.00001 3.70645 5.00001 4.16669V16.6667ZM10.8333 16.6667V15H9.16667V16.6667H10.8333ZM6.66667 6.66669C6.66667 6.20645 7.03977 5.83335 7.50001 5.83335H8.33334C8.79358 5.83335 9.16667 6.20645 9.16667 6.66669C9.16667 7.12692 8.79358 7.50002 8.33334 7.50002H7.50001C7.03977 7.50002 6.66667 7.12692 6.66667 6.66669ZM11.6667 5.83335C11.2064 5.83335 10.8333 6.20645 10.8333 6.66669C10.8333 7.12692 11.2064 7.50002 11.6667 7.50002H12.5C12.9602 7.50002 13.3333 7.12692 13.3333 6.66669C13.3333 6.20645 12.9602 5.83335 12.5 5.83335H11.6667ZM6.66667 10C6.66667 9.53978 7.03977 9.16669 7.50001 9.16669H8.33334C8.79358 9.16669 9.16667 9.53978 9.16667 10C9.16667 10.4603 8.79358 10.8334 8.33334 10.8334H7.50001C7.03977 10.8334 6.66667 10.4603 6.66667 10ZM11.6667 9.16669C11.2064 9.16669 10.8333 9.53978 10.8333 10C10.8333 10.4603 11.2064 10.8334 11.6667 10.8334H12.5C12.9602 10.8334 13.3333 10.4603 13.3333 10C13.3333 9.53978 12.9602 9.16669 12.5 9.16669H11.6667Z"
          fill={color}
        />
      </svg>
    </div>
  )
}
export default AgenciesIcon
