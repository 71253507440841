import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  AgxButton,
  AgxTextInput,
  AgxCheckbox,
  AgxRow,
  AgxColumn,
  AgxBodyText,
  AgxTextArea,
  Images,
  AgxMultiSelect,
  MarketingItemType,
} from '@urbanx/agx-ui-components'
import Popup from 'components/Popup/Popup'
import { MarketingPackage, MarketingItem } from 'types/agency'
import DeleteConfirmation from 'components/delete-confirmation/DeleteConfirmation'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  UpdateMarketingPackage,
  AddMarketingPackage,
  RemoveMarketingPackage,
} from 'services'
import { Agent } from 'types/agency'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { FormPrompt } from 'components/FormPrompt'
import './editMarketingPackage.css'
import xorWith from 'lodash/xorWith'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'

interface EditMarketingProps {
  onBack: () => void
  marketingPackage: MarketingPackage | undefined
  includedStandardInclusions: string[]
  includedAddons: string[]
  allItems: MarketingItem[]
  agencyId: string
  allAvailableAgents: Agent[]
}

const enum InclusionsType {
  STANDARD = 'standard',
  ADDONS = 'addons',
}

const STANDARD_INCLUSIONS_TITLE = 'Select standard inclusions to add'
const ADDON_INCLUSIONS_TITLE = 'Select add-ons to add'
const REA_LISTING_LABEL = 'REA Listing'
const DOMAIN_LISTING_LABEL = 'Domain Listing'
const SELECT_ALL_LABEL = 'Select All'
const REA_LISTING: MarketingItem = {
  id: REA_LISTING_LABEL,
  productName: REA_LISTING_LABEL,
  itemType: MarketingItemType.AgentToFill,
}
const DOMAIN_LISTING: MarketingItem = {
  id: DOMAIN_LISTING_LABEL,
  productName: DOMAIN_LISTING_LABEL,
  itemType: MarketingItemType.AgentToFill,
}
const SELECT_ALL: MarketingItem = {
  id: SELECT_ALL_LABEL,
  productName: SELECT_ALL_LABEL,
  itemType: MarketingItemType.Custom,
}

const EditMarketingPackage = (props: EditMarketingProps) => {
  const {
    onBack,
    marketingPackage,
    includedStandardInclusions,
    includedAddons,
    allItems,
    agencyId,
    allAvailableAgents,
  } = props
  const [standardItems, setStandardItems] = useState<MarketingItem[]>([])
  const [addons, setAddons] = useState<MarketingItem[]>([])
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [packageName, setPackageName] = useState('')
  const [packageDisplayName, setPackageDisplayName] = useState('')
  const [addInclusionsTitle, setAddInclusionsTitle] = useState('')
  const [specialConditions, setSpecialConditions] = useState('')
  const [showEditInclusions, setShowEditInclusions] = useState(false)
  const [isItemisedPricing, setIsItemisedPricing] = useState(false)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [forceCheck, setForceCheck] = useState(false)
  const [standardItemsSelectAll, setStandardItemsSelectAll] = useState(false)
  const [addonsSelectAll, setAddonsSelectAll] = useState(false)
  const [isStandardItemsIndeterminate, setIsStandardItemsIndeterminate] =
    useState(false)
  const [isAddonsIndeterminate, setIsAddonsIndeterminate] = useState(false)

  const allAgentOptions = useMemo(() => {
    return allAvailableAgents.map((agent) => ({
      value: agent.id,
      label: `${agent.name?.firstName} ${agent.name?.lastName}`,
    }))
  }, [allAvailableAgents])

  const inclusionsList = useMemo(() => {
    return allItems
      .filter((i) => !addons.find((a) => a.id === i.id))
      .sort((a, b) => {
        const sortedItems = standardItems.map((item) => item.id)
        return sortedItems.indexOf(a.id) - sortedItems.indexOf(b.id)
      })
      .reduce(
        (acc, curr) => {
          acc.push(curr)
          return acc
        },
        [SELECT_ALL, REA_LISTING, DOMAIN_LISTING]
      )
  }, [allItems, addons])

  const addonsList = useMemo(() => {
    return allItems
      .filter((i) => !standardItems.find((s) => s.id === i.id))
      .sort((a, b) => {
        const sortedAddons = addons.map((addon) => addon.id)
        return sortedAddons.indexOf(a.id) - sortedAddons.indexOf(b.id)
      })
      .reduce(
        (acc, curr) => {
          acc.push(curr)
          return acc
        },
        [SELECT_ALL]
      )
  }, [allItems, standardItems])

  const [userAccessIds, setUserAccessIds] = useState<string[]>(
    marketingPackage?.assignedAgentIds || []
  )

  const [, getAuthToken] = useAzureAuth()

  const queryClient = useQueryClient()
  const { mutate: updatePackage } = useMutation(UpdateMarketingPackage, {
    onSuccess: () => {
      setIsFormDirty(false)
      queryClient.invalidateQueries({
        queryKey: [`packages-${agencyId}`, agencyId],
      })
      onBack()
    },
  })
  const { mutate: addPackage } = useMutation(AddMarketingPackage, {
    onSuccess: () => {
      setIsFormDirty(false)
      queryClient.invalidateQueries({
        queryKey: [`packages-${agencyId}`, agencyId],
      })
      onBack()
    },
  })
  const { mutate: removePackage } = useMutation(RemoveMarketingPackage, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`packages-${agencyId}`, agencyId],
      })
      onBack()
    },
  })

  useEffect(() => {
    checkSelectAllState(standardItems, inclusionsList, InclusionsType.STANDARD)
  }, [standardItems])

  useEffect(() => {
    checkSelectAllState(addons, addonsList, InclusionsType.ADDONS)
  }, [addons])

  useEffect(() => {
    if (standardItemsSelectAll) {
      setStandardItems([
        ...inclusionsList.filter((i) => i.id !== SELECT_ALL_LABEL),
      ])
    } else {
      setStandardItems([])
    }
  }, [standardItemsSelectAll])

  useEffect(() => {
    if (addonsSelectAll) {
      setAddons([...addonsList.filter((i) => i.id !== SELECT_ALL_LABEL)])
    } else {
      setAddons([])
    }
  }, [addonsSelectAll])

  useEffect(() => {
    if (includedStandardInclusions) {
      const standardItems: MarketingItem[] = includedStandardInclusions
        .map((inclusion) => allItems.find((item) => item.id === inclusion))
        .filter((item) => item !== undefined)
        .map((item) => item as MarketingItem)

      if (marketingPackage?.includeRealEstateListing)
        standardItems.splice(0, 0, REA_LISTING)

      if (marketingPackage?.includeDomainListing)
        standardItems.splice(1, 0, DOMAIN_LISTING)

      setStandardItems(standardItems)
    }

    if (includedAddons) {
      setAddons(
        includedAddons.flatMap((addon) => {
          const item = allItems.find((item) => item.id === addon)
          return item !== undefined ? [item as MarketingItem] : []
        })
      )
    }

    if (marketingPackage) {
      setPackageName(marketingPackage.name || '')
      setPackageDisplayName(marketingPackage.displayName || '')
      setSpecialConditions(marketingPackage?.specialConditions || '')
    }
  }, [includedStandardInclusions, includedAddons, marketingPackage])

  const onDeleteItemHandler = () => {
    setShowDeleteConfirmation(true)
  }

  const onConfirmDelete = useCallback(() => {
    if (marketingPackage?.id) {
      removePackage({
        AgencyId: agencyId,
        MarketingPackageId: marketingPackage.id,
        getAuthToken,
      })
    } else {
      onBack()
    }
  }, [marketingPackage?.id, agencyId, removePackage, onBack])

  const handleOnDragEndStandard = useCallback(
    (result: DropResult) => {
      if (!result.destination) return
      if (standardItems && standardItems?.length > 0) {
        const items = [...standardItems]
        const [reorderedItems] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItems)

        if (items !== standardItems) setIsFormDirty(true)
        setStandardItems(items)
      }
    },
    [standardItems, setStandardItems]
  )

  const handleOnDragEndAddons = useCallback(
    (result: DropResult) => {
      if (!result.destination) return
      if (addons && addons?.length > 0) {
        const originalAddons = [...addons]
        const [reorderedAddons] = originalAddons.splice(result.source.index, 1)
        originalAddons.splice(result.destination.index, 0, reorderedAddons)

        if (originalAddons !== addons) setIsFormDirty(true)
        setAddons(originalAddons)
      }
    },
    [addons, setAddons]
  )

  const editInclusions = useCallback(
    (inclusionsType: InclusionsType) => {
      if (inclusionsType === InclusionsType.STANDARD) {
        setAddInclusionsTitle(STANDARD_INCLUSIONS_TITLE)
      } else {
        setAddInclusionsTitle(ADDON_INCLUSIONS_TITLE)
      }
      setShowEditInclusions(true)
    },
    [setAddInclusionsTitle, setShowEditInclusions]
  )

  const onSavePackageChanges = () => {
    const itemsList: string[] = standardItems
      .filter((i) => !!i.id)
      .map((i) => i.id!)
    const addonsList: string[] = addons.filter((i) => !!i.id).map((i) => i.id!)

    const isReaListing = !!standardItems.find(
      (i) => i.productName === REA_LISTING_LABEL
    )
    const isDomainListing = !!standardItems.find(
      (i) => i.productName === DOMAIN_LISTING_LABEL
    )
    if (marketingPackage?.id) {
      updatePackage({
        AgencyId: agencyId,
        MarketingPackageId: marketingPackage?.id,
        Name: packageName,
        DisplayName: packageDisplayName,
        IncludeRealEstateListing: isReaListing,
        IncludeDomainListing: isDomainListing,
        DisplayItemisedPricingOnPdf: isItemisedPricing,
        Items: itemsList,
        AddOns: addonsList,
        assignedAgentIds: userAccessIds,
        specialConditions: specialConditions,
        getAuthToken,
      })
    } else {
      addPackage({
        AgencyId: agencyId,
        Name: packageName,
        DisplayName: packageDisplayName,
        IncludeRealEstateListing: isReaListing,
        IncludeDomainListing: isDomainListing,
        DisplayItemisedPricingOnPdf: isItemisedPricing,
        Items: itemsList,
        AddOns: addonsList,
        specialConditions: specialConditions,
        assignedAgentIds: userAccessIds,
        getAuthToken,
      })
    }
  }

  // Set the select all and indeterminate state based on the inclusions list
  const checkSelectAllState = (
    inclusionItems: MarketingItem[],
    list: MarketingItem[],
    type: InclusionsType
  ) => {
    if (inclusionItems.length === 0) {
      if (type === InclusionsType.STANDARD) {
        setStandardItemsSelectAll(false)
        setIsStandardItemsIndeterminate(false)
      } else {
        setAddonsSelectAll(false)
        setIsAddonsIndeterminate(false)
      }
    } else if (
      inclusionItems.length ===
        list.filter((i) => i.id !== SELECT_ALL_LABEL).length &&
      isEmpty(
        xorWith(
          inclusionItems,
          list.filter((i) => i.id !== SELECT_ALL_LABEL),
          isEqual
        )
      )
    ) {
      if (type === InclusionsType.STANDARD) {
        setStandardItemsSelectAll(true)
        setIsStandardItemsIndeterminate(false)
        setIsAddonsIndeterminate(false)
      } else {
        setAddonsSelectAll(true)
        setIsAddonsIndeterminate(false)
        setIsStandardItemsIndeterminate(false)
      }
    } else {
      type === InclusionsType.STANDARD
        ? setIsStandardItemsIndeterminate(true)
        : setIsAddonsIndeterminate(true)
    }
  }

  const renderEditInclusions = (item: MarketingItem) => {
    let checked = false

    if (item.id === SELECT_ALL_LABEL) {
      checked =
        addInclusionsTitle === STANDARD_INCLUSIONS_TITLE
          ? standardItemsSelectAll
          : addonsSelectAll
    } else {
      if (addInclusionsTitle === STANDARD_INCLUSIONS_TITLE) {
        checked = standardItems.some((s) => s.id === item.id)
      } else {
        checked = addons.some((s) => s.id === item.id)
      }
    }

    const updateInclusionsList = (value: boolean) => {
      const isStandardInclusionsList =
        addInclusionsTitle === STANDARD_INCLUSIONS_TITLE

      // Set the type of inclusions list
      const inclusionsListItems = isStandardInclusionsList
        ? standardItems
        : addons

      if (value) {
        if (item.id === SELECT_ALL_LABEL) {
          addInclusionsTitle === STANDARD_INCLUSIONS_TITLE
            ? setStandardItemsSelectAll(value)
            : setAddonsSelectAll(value)
        } else {
          const alreadyExist = inclusionsListItems.some((i) => i.id === item.id)
          if (!alreadyExist) {
            setIsFormDirty(true)

            isStandardInclusionsList
              ? setStandardItems([...inclusionsListItems, item])
              : setAddons([...inclusionsListItems, item])
          }
        }
      } else {
        if (item.id === SELECT_ALL_LABEL) {
          addInclusionsTitle === STANDARD_INCLUSIONS_TITLE
            ? setStandardItemsSelectAll(value)
            : setAddonsSelectAll(value)
        } else {
          if (
            JSON.stringify(inclusionsListItems) !==
            JSON.stringify(inclusionsListItems.filter((i) => i.id !== item.id))
          ) {
            setIsFormDirty(true)
          }

          isStandardInclusionsList
            ? setStandardItems(
                inclusionsListItems.filter((i) => i.id !== item.id)
              )
            : setAddons(inclusionsListItems.filter((i) => i.id !== item.id))
        }
      }
    }

    return (
      <AgxRow
        key={`${item.id}`}
        spaceBetween
        extraClasses="borderBottomContainer checkboxContainer"
      >
        <AgxCheckbox
          id="chkProductName"
          indeterminate={
            item.id === SELECT_ALL_LABEL &&
            (addInclusionsTitle === STANDARD_INCLUSIONS_TITLE
              ? isStandardItemsIndeterminate
              : isAddonsIndeterminate)
          }
          subLabel={item.productName}
          naked
          parentControlValue
          defaultValue={checked}
          onValueChanged={({ value }: { value: boolean }) =>
            updateInclusionsList(value)
          }
        />
        <span>
          {item.pricePerUnit ? `$${item.pricePerUnit.toFixed(2)}` : '---'}
        </span>
      </AgxRow>
    )
  }

  const renderItem = (
    item: MarketingItem,
    index: number,
    isDraggable: boolean
  ) => {
    if (!isDraggable) {
      return (
        <li key={`item-${index}`}>
          <AgxRow spaceBetween extraClasses="borderBottomContainer">
            <div className="thPackageNameContainer__marketing">
              <AgxBodyText small>{item.productName}</AgxBodyText>
            </div>
            <AgxBodyText small>
              {item.pricePerUnit ? `$${item.pricePerUnit.toFixed(2)}` : '---'}
            </AgxBodyText>
          </AgxRow>
        </li>
      )
    }
    return (
      <Draggable key={item.id} draggableId={item.productName} index={index}>
        {(provided) => (
          <li
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <AgxRow spaceBetween extraClasses="borderBottomContainer">
              <div className="thPackageNameContainer__marketing">
                <Images.DragDropIcon />
                <AgxBodyText small>{item.productName}</AgxBodyText>
              </div>
              <AgxBodyText small>
                {item.pricePerUnit ? `$${item.pricePerUnit.toFixed(2)}` : '---'}
              </AgxBodyText>
            </AgxRow>
          </li>
        )}
      </Draggable>
    )
  }

  const onBackClick = () => {
    isFormDirty ? setForceCheck(true) : onBack()
  }

  return (
    <AgxColumn veryLargeGap extraClasses="growOne">
      <FormPrompt
        hasUnsavedChanges={isFormDirty}
        forceCheck={forceCheck}
        resetForceCheck={(value: boolean) => setForceCheck(value)}
        onBack={onBack}
      />
      <AgxRow veryLargeGap spaceBetween extraClasses="borderBottomContainer">
        <AgxButton
          text="Marketing Package"
          large
          naked
          onClick={() => onBackClick()}
        />
        <AgxRow veryLargeGap>
          <AgxButton
            text="Delete package"
            medium
            naked
            danger
            onClick={onDeleteItemHandler}
          />
          <AgxButton
            text="Save Changes"
            medium
            primary
            onClick={onSavePackageChanges}
          />
        </AgxRow>
      </AgxRow>
      <AgxRow veryLargeGap fill>
        <AgxColumn fill extraLargeGap extraClasses="checkboxContainer">
          <AgxTextInput
            id="txtPackageName"
            stretch
            label="Package name"
            noOptionalLabel
            defaultValue={packageName}
            onInputValueChange={({ value }: { value: string }) => {
              if (value && packageName !== value) {
                setIsFormDirty(true)
              }
              setPackageName(value)
            }}
            parentControlValue={true}
          />
          <AgxTextInput
            id="txtSearch"
            stretch
            label="Display name on PDF"
            noOptionalLabel
            placeholder={packageName}
            defaultValue={packageDisplayName}
            onInputValueChange={({ value }: { value: string }) => {
              if (value && packageDisplayName !== value) {
                setIsFormDirty(true)
              }
              setPackageDisplayName(value)
            }}
            parentControlValue={true}
          />
          <AgxMultiSelect
            id={`userAccess-${marketingPackage?.id}`}
            label="User Access"
            hideOptionalLabel
            defaultValue={userAccessIds}
            onValueChanged={({ value }: { value: string[] }) => {
              if (!isEqual(userAccessIds, value)) {
                setIsFormDirty(true)
              }
              setUserAccessIds(value ?? [])
            }}
            options={allAgentOptions}
          />
        </AgxColumn>
        <AgxColumn fill extraLargeGap extraClasses="checkboxContainer">
          <AgxTextArea
            id="agx-docuSignEmailMessage"
            label="Special Conditions"
            placeholder="Nil"
            parentControlValue
            defaultValue={specialConditions}
            onInputValueChange={({ value }: { value: string }) => {
              if (value && specialConditions !== value) {
                setIsFormDirty(true)
              }
              setSpecialConditions(value)
            }}
            rows={6}
            maxLength={925}
            showCharCount
            noOptionalLabel
            stretch
          />
          <AgxCheckbox
            id="chkDisplayOnPdf"
            subLabel="Display itemised pricing on PDF"
            naked
            defaultValue={marketingPackage?.displayItemisedPricingOnPdf}
            onValueChanged={({ value }: { value: boolean }) => {
              if (
                marketingPackage?.displayItemisedPricingOnPdf &&
                marketingPackage?.displayItemisedPricingOnPdf !== value
              ) {
                setIsFormDirty(true)
              }
              setIsItemisedPricing(value)
            }}
          />
        </AgxColumn>
      </AgxRow>
      <AgxRow veryLargeGap>
        <AgxColumn fill mediumGap extraClasses="sectionStyle">
          <AgxRow spaceBetween extraClasses="borderBottomContainer">
            <AgxBodyText medium>Standard inclusions</AgxBodyText>
            <AgxButton
              text="Edit Inclusions"
              medium
              hollow
              onClick={() => editInclusions(InclusionsType.STANDARD)}
            />
          </AgxRow>
          {standardItems.length === 0 ? (
            <AgxRow>
              <AgxBodyText medium>
                These will be ticked by default but can be removed by the agent
                when they modify the package.
              </AgxBodyText>
            </AgxRow>
          ) : null}
          <DragDropContext
            onDragEnd={(result) => handleOnDragEndStandard(result)}
          >
            <Droppable droppableId="standardItems">
              {(provided) => (
                <ul
                  className="marketingPackagesList"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {standardItems &&
                    standardItems.map((item, index) =>
                      item.productName === REA_LISTING_LABEL ||
                      item.productName === DOMAIN_LISTING_LABEL
                        ? renderItem(item, index, false)
                        : renderItem(item, index, true)
                    )}

                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </AgxColumn>
        <AgxColumn mediumGap fill extraClasses="sectionStyle">
          <AgxRow spaceBetween extraClasses="borderBottomContainer">
            <AgxBodyText medium>Optional Add-ons</AgxBodyText>
            <AgxButton
              text="Edit Inclusions"
              medium
              hollow
              onClick={() => editInclusions(InclusionsType.ADDONS)}
            />
          </AgxRow>
          {addons.length === 0 ? (
            <AgxRow>
              <AgxBodyText medium>
                These will be displayed unchecked by default to the agent in a
                separate section for upselling.
              </AgxBodyText>
            </AgxRow>
          ) : null}
          <DragDropContext
            onDragEnd={(result) => handleOnDragEndAddons(result)}
          >
            <Droppable droppableId="addonItems">
              {(provided) => (
                <ul
                  className="marketingPackagesList"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {addons &&
                    addons.map((addon, index) =>
                      renderItem(addon, index, true)
                    )}

                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </AgxColumn>
      </AgxRow>
      <DeleteConfirmation
        name={marketingPackage?.name || ''}
        title="Delete marketing package?"
        lineOne="Are you sure you want to delete"
        lineTwo="This cannot be undone."
        primaryActionTitle="Permanently delete package"
        secondaryActionTitle="No, cancel"
        showPopUp={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={() => onConfirmDelete()}
      />

      <Popup
        title={addInclusionsTitle}
        isOpen={showEditInclusions}
        onClose={() => setShowEditInclusions(false)}
        actionButtons={[
          {
            title: 'Update package',
            isPrimary: true,
            clickHandler: () => setShowEditInclusions(false),
          },
          {
            title: 'Cancel',
            clickHandler: () => setShowEditInclusions(false),
          },
        ]}
        size={{ widthInPX: 640 }}
      >
        <AgxColumn veryLargeGap>
          <AgxRow extraClasses="borderBottomContainer">
            <AgxTextInput
              id="txtSearch"
              label=""
              placeholder="Search"
              noOptionalLabel
              stretch
              onInputValueChange={({ value }: { value: string }) =>
                console.log(value)
              }
              leftIcon={<Images.SearchOutline />}
            />
          </AgxRow>
          {addInclusionsTitle === STANDARD_INCLUSIONS_TITLE
            ? inclusionsList.map((item) => renderEditInclusions(item))
            : addonsList.map((item) => renderEditInclusions(item))}
        </AgxColumn>
      </Popup>
    </AgxColumn>
  )
}

export default EditMarketingPackage
