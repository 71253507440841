import { QueryKey } from '@tanstack/react-query'
import { Form } from '@urbanx/agx-ui-components'
import CampaignsService from 'services/campaignsService'

const getFormConfig = async (
  { queryKey }: { queryKey: QueryKey },
  getAuthToken: () => Promise<string | void>
): Promise<Form | undefined> => {
  const token = await getAuthToken()
  if (!token) return

  const service = new CampaignsService(token)
  if (!service) return

  const formType = queryKey[1] as string
  const campaignId = queryKey[2] as string
  const continuingFromFormId = queryKey[3] as string
  const { data: formConfig } = await service.campaignsService.get(
    `StaffGetFormConfiguration`,
    {
      FormType: formType,
      CampaignId: campaignId,
      ContinuingFromFormId: continuingFromFormId,
    }
  )

  return formConfig || {}
}

export default getFormConfig
